<template>
    <div :class="{ contents:!showTitle, 'flex-col-centered selection':showTitle }">
        <label v-if=showTitle :for=id><h3 class="header">Period Selection:</h3></label>
        <select :id=id
            v-model=nullVarWrap
            class="dropdown"
            :required=required
        >
            <option v-if=hasNullSelector value="" >
                {{ nullSelectorName }}
            </option>
            <option
                v-for="period in periods"
                :value="period"
            >
                {{ period }}
            </option>
        </select>
    </div>
</template>
<script setup lang="ts">
import { onMounted, computed, watch } from 'vue';
import "@/types/Date";
import DatePeriod from '@/types/DatePeriod';
import useVModelDefaultVar from '@/services/composables/VModelDefaultVar';
import { marketSelection } from '@/services/helpers/Helper';

const props = withDefaults(defineProps<{
    id?: string;
    modelValue?: DatePeriod;
    hasNullSelector?: boolean;
    showTitle?: boolean;
    nullSelectorName?: string;
    defaultRefDate?: DatePeriod;
    filter?: (date: DatePeriod)=>boolean|undefined;
    required?: boolean;
    updateOnPropertyChange?: any;
}>(),{
    nullSelectorName: 'All',
});
const emits = defineEmits<{
    (e: 'update:modelValue', val?: DatePeriod): void;
}>();
const nullValue = new DatePeriod(null);
const periods = computed(()=>props.filter?generatePeriods().filter(props.filter):generatePeriods());
const periodSelection = useVModelDefaultVar(props, emits, 'modelValue', getDefault());
const nullVarWrap = computed({
    get() {
        return periodSelection.value.isValid() ? periodSelection.value : "";
    },
    set(val: "" | DatePeriod) {
        periodSelection.value = val==="" ? nullValue : val;
    }
});
onMounted(()=>{
    if (!props.modelValue?.isValid() && periodSelection.value !== nullValue) periodSelection.value = getDefault();
});
watch(
  () => props.updateOnPropertyChange,
  () => {
    periodSelection.value = nullValue;
  }
);
function getDefault() {
    if (props.hasNullSelector) return nullValue;
    const lastMonth = new Date().addMonths(-1);
    return new DatePeriod(lastMonth);
}
function generatePeriods() {
    const refDate = props.defaultRefDate ?? marketSelection.value === 'GB' ? new DatePeriod(2023,4) : new DatePeriod(2024,0);
    const date = new DatePeriod(new Date());

    const nMonths = date.diffMonths(refDate)+1;
    const listOfDates = new Array<DatePeriod>(nMonths);
    for (const i of new Array(nMonths).keys()) {
        listOfDates[i] = new DatePeriod(date);
        date.addMonths(-1);
    }
    return listOfDates;
}
</script>
<style>
.contents {
    display:contents;
}
</style>