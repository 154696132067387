<template>
    <div style="display: flex; justify-content: center; align-items: center;">Your session has expired. To authenticate your account please follow the instructions in a&ensp;
        <a href="#" @click="triggerAuth" style="text-decoration: none;">pop-up</a>.
    </div>
</template>


<script setup lang="ts">
import { authService } from "@/auth/authSingleton";
import log from "loglevel";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const popupOpen = ref(false);

onMounted(async () => {
    await triggerAuth()
});

async function triggerAuth () {
    if (popupOpen.value) return;
    try {
        popupOpen.value = true; 
        await authService.authenticate();
        const redirectPath = route.query.redirect || "/";
        router.replace(redirectPath as string);
    } catch (error) {
        log.error("Authentication failed:", error);
    } finally {
        popupOpen.value = false;
    }
};
</script>
